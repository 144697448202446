import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
window.addEventListener('load', (event) => {
  //console.log('page is fully loaded');
  document.getElementById("loaddiv").style.display = "none";
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <div class="loader-wrapper" id="loaddiv">
    <div class="loader" style={{width:'140px'}}><img src="/images/alizz-logo.png" className="header-logo" alt="Alizz" style={{'max-width':'140px'}} /></div>
  </div>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
