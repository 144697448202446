//Make the necessary imports
import React from 'react';
import { useParams,Navigate } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import Slider from "react-slick";
import parse from 'html-react-parser';
//import app from '../App';
//Create the query



export default function Pagedetails() {
	const lang = sessionStorage.getItem('lang');
	//const pid = sessionStorage.getItem('pageid');
    //Get the id from the URL
    
	//console.log(slug); 
	//const { id } = pid;

	
	const PAGE = gql`
query PageDetails($slug: String) {
  pages(filters: {slug: {eq: $slug}}) {
   data{
    attributes{
      Title
      Content
	  TabContent{
        Title
        Content
		FeaturedImage{
          data{
            attributes{
              url
            }
          }
		}
      }
	  ExtraContent
	  RightsideBar
      Cover{
        data{
          attributes{
            url
          }
        }
      }
	  slug
      localizations{
        data{
          id
          attributes{
            locale
            Title
            Content
			TabContent{
        Title
        Content
		FeaturedImage{
          data{
            attributes{
              url
            }
          }
		}
      }
	  ExtraContent
	  RightsideBar
      Cover{
        data{
          attributes{
            url
          }
        }
      }
	  slug
          }
        }
      }
    }
  }
  }
}
`

const {slug} = useParams();
  //console.log(slug);

    //Pass variables to the query and execute it. Store the results in an object
    const { loading, error, data } = useQuery(PAGE, {
    variables: { slug: slug },
  });

    //Display messages accordingly
      if (loading) return <p>Loading...</p>
      if (error) return <p>Error :(</p>
    
      //console.log(data);
 //const post = data.find((post) => post.slug === slug);
//console.log(post);
if(data.pages.data[0].attributes.localizations.data[0]){

	document.title = data.pages.data[0].attributes.localizations.data[0].attributes.Title;
	var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
	var pth='https://alizzapi.alizzislamic.com/';
	
  return (
  <>
  <div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
		 {/* <Slider {...settings}>
            <div className="row">
			{(() => {
        if(data.pages.data[0].attributes.localizations.data[0].attributes.Cover.data!=null){
              return (<><div className="col-md-6">
				<img src={pth + data.pages.data[0].attributes.localizations.data[0].attributes.Cover.data.attributes.url} alt={data.pages.data[0].attributes.localizations.data[0].attributes.Title} className=" img-fluid"/>
              </div></>); 
        }
    })()}
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h3>{data.pages.data[0].attributes.localizations.data[0].attributes.Title}</h3>
                </div>
              </div>
            </div>
			</Slider>*/}
			
			
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>{data.pages.data[0].attributes.localizations.data[0].attributes.Title}</h3>
              </div>
			</div>
          </div>

    </div>
	</div>
	
	
	<section className="section about_content">
    <div className="container aboutcontent">
      <div className="row">
        <div className="col-md-12 mb-50">
          {(() => {
        if(data.pages.data[0].attributes.localizations.data[0].attributes.Content!=null){
	return (<>{parse(data.pages.data[0].attributes.localizations.data[0].attributes.Content)}</>); 
        }
    })()}
	
        </div>
      </div>
	  
	  <div className="row">
	  {(() => {
        if(data.pages.data[0].attributes.localizations.data[0].attributes.RightsideBar!=null){
	  return (<><div className="col-md-9">
	  <div id="accordion">
	  {data.pages.data[0].attributes.localizations.data[0].attributes.TabContent.map((post, index) => {
		  var ff="#ff";
		  var fff="ff";
			   return (
				<>
        <div className="card">
          <div className="card-header">
            <a className={`btn collaptxt ${index==0 ? "" : "collapsed"}`} data-bs-toggle="collapse" aria-expanded={`${index==0 ? "true" : "false"}`} href={ff+index}>
              {parse(post.Title)}
            </a>
          </div>
          <div id={fff+index} className={`collapse ${index==0 ? "show" : ""}`} data-bs-parent="#accordion">
            <div className="card-body">
			<div className="row">			
			{(() => {
        if(post.FeaturedImage.data!=null){
			  return (<><div className="col-md-3"><img src={pth + post.FeaturedImage.data.attributes.url} alt={post.Title} className="imgexebio img-fluid"/></div><div className="col-md-9">{parse(post.Content)}</div></>); 
			  }else{
				return (<><div className="col-md-12">{parse(post.Content)}</div></>);   
			  }
    })()}
            </div>  
            </div>
          </div>
        </div>
		</>
			   )
			})}
		</div>
		<div className="extracnt">
		 {(() => {
        if(data.pages.data[0].attributes.localizations.data[0].attributes.ExtraContent!=null){
	return (<>{parse(data.pages.data[0].attributes.localizations.data[0].attributes.ExtraContent)}</>); 
        }
    })()}
		</div>
	  </div></>); 
		}else{
		return (<><div className="col-md-12">
		<div id="accordion">
	  {data.pages.data[0].attributes.localizations.data[0].attributes.TabContent.map((post, index) => {
		  var ff="#ff";
		  var fff="ff";
			   return (
				<>
        <div className="card">
          <div className="card-header">
            <a className={`btn collaptxt ${index==0 ? "" : "collapsed"}`} data-bs-toggle="collapse" aria-expanded={`${index==0 ? "true" : "false"}`} href={ff+index}>
              {parse(post.Title)}
            </a>
          </div>
          <div id={fff+index} className={`collapse ${index==0 ? "show" : ""}`} data-bs-parent="#accordion">
            <div className="card-body">
			<div className="row">			
			{(() => {
        if(post.FeaturedImage.data!=null){
			  return (<><div className="col-md-3"><img src={pth + post.FeaturedImage.data.attributes.url} alt={post.Title} className="imgexebio img-fluid"/></div><div className="col-md-9">{parse(post.Content)}</div></>); 
			  }else{
				return (<><div className="col-md-12">{parse(post.Content)}</div></>);   
			  }
    })()}
            </div>  
            </div>
          </div>
        </div>
		</>
			   )
			})}
		</div>
		<div className="extracnt">
		 {(() => {
        if(data.pages.data[0].attributes.localizations.data[0].attributes.ExtraContent!=null && data.pages.data[0].attributes.localizations.data[0].attributes.ExtraContent!=""){
	return (<>{parse(data.pages.data[0].attributes.localizations.data[0].attributes.ExtraContent)}</>); 
        }
    })()}
		</div>
		</div></>);	
		}
    })()}
	  

		
		{(() => {
        if(data.pages.data[0].attributes.localizations.data[0].attributes.RightsideBar!=null && data.pages.data[0].attributes.localizations.data[0].attributes.RightsideBar!=""){
		return (<><div className="col-md-3"><div className="online-rightblock">
		{parse(data.pages.data[0].attributes.localizations.data[0].attributes.RightsideBar)}
		</div></div></>); 
		}
    })()}
		</div>
	  
	  
    </div>
  </section>
  
  
	

	</>
  );
}else{
	return (<>
	<Navigate to="/" />
	<section className="section about_content">
    <div className="container aboutcontent">
      <div className="row">
        <div className="col-md-12 error text-center">
          <h3>404 error found</h3>
        </div>
      </div>
    </div>
  </section></>
	);
}
}